<template>
  <div class="layout">
    <div class="navigation">
      <div class="navigation-logo">
        <el-image style="width: 5rem" :src="require('@/static/images/member/huochuan.png')" fit="cover"></el-image>
        <div class="navigation-title">{{ $t('service.title') }}</div>
      </div>
      <el-menu
          :default-active="activeMenu"
          active-text-color="#194D8F"
          router
          class="el-menu-vertical-demo">
        <el-menu-item :index="item.index" v-for="item in menuItems" :key="item.id">
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="operate">
      <transition name="pop">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  // 在你的 Vue 组件中
  data() {
    return {
    };
  },
  computed: {
    menuItems() {
      return  [
        {
          id: 1,
          title: this.$t('layout.service.aboutUs'),
          index: '/service/about-Us'
        },
        {
          id: 2,
          title: this.$t('layout.service.privacyStatement'),
          index: '/service/privacy'
        },
        {
          id: 3,
          title: this.$t('layout.service.termsAndDisclaimer'),
          index: '/service/term'
        },
        {
          id: 4,
          title: this.$t('layout.service.carriageAgreement'),
          index: '/service/carriage-treaty'
        },
        {
          id: 5,
          title: this.$t('layout.service.contactUs'),
          index: '/service/contact-Us'
        },
      ]
    },
    activeMenu() {
      const route = this.$route
      const { path } = route
      return path
    },
  },
  components: {
  },
  created() {
  },
  methods: {
  }
}

</script>

<style scoped lang="scss">
.pop-enter-active, .pop-leave-active {
  transition: transform 0.5s ease;
}
.pop-enter, .pop-leave-to /* .pop-leave-active in <2.1.8 */ {
  transform: scale(0);
}
.layout {
  width: 75%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 39rem);
  box-sizing: border-box;
  .navigation {
    width: 21%;
    height: auto;
    .navigation-logo {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: #fff;
      padding: 1.8rem 1.2rem;
      border: 0.1rem solid rgba(237,237,237,1);
      .navigation-title {
        font-size: 2.4rem;
        font-weight: bold;
        color: #194D8F;
      }
    }
  }
  .operate {
    background-color: #fff;
    width: 77%;
  }
}
</style>
